import { FC } from 'react';
import Button, { IButton } from '@/components/Button';
import RichTextRenderer from '@/components/RichText';

export interface ITextWithImage {
    content: {
        content: {
            json: any;
        }
        image: {
            title: string;
            description: string;
            url: string;
        }
        ctaButton?: IButton
    }
}

const TextWithImage: FC<ITextWithImage> = function ({ content}) {
    return (
        <section className="bg-whitepure max-sm:pb-0 relative overflow-hidden md:min-h-[25rem] lg:min-h-[35rem] md:flex" >
            <div className="container self-center">
                <div className="flex justify-end">
                    <div className="sm:w-3/5 lg:w-2/5 z-10">
                        <div className="text-center">
                            <RichTextRenderer content={content.content}/>
                            {content.ctaButton && <Button {...content.ctaButton} className="button-secondary mt-4" />}
                        </div>
                    </div>
                </div>
            </div>
            {content.image && <img src={content.image.url} alt={content.image.description || content.image.title } className="sm:absolute top-0 left-0 bottom-0 sm:h-full max-w-full sm:w-[50vw] lg:w-[60vw] overflow-hidden object-cover object-right-top" />}
        </section>
    );
};

export default TextWithImage;

import { FC } from 'react';

export interface IButton {
    label: string;
    url?: string;
    target?: Boolean;
    page?: {
        menuTitle: string;
    };
    className?: string;
}

const Button: FC<IButton> = function ({ label, url, target, page, className}) {
    return (
        <a href={url || page?.menuTitle} target={target ? '_blank' : '_self'} className={`button ${className}`} >{label}</a>
    );
};

export default Button;

import { FC } from 'react';
import Button, { IButton } from '@/components//Button';
import RichTextRenderer from '../RichText';

export interface ICard {
    content: {
        json: {};
    }
    image: {
        title: string;
        description: string;
        url: string;
    }
    ctaButton?: IButton;
    className: string;
}

const Card: FC<ICard> = function ({ content, image, ctaButton, className }) {
    return (
        <div className={`card flex flex-col max-sm:flex-col-reverse sm:max-md:flex-row text-center gap-x-6 ${className}`}>
            <div className="sm:max-md:w-2/3">
                <RichTextRenderer content={content} className="flex flex-col gap-4"/>
                {ctaButton && <div className="text-center"><Button label={ctaButton?.label} url={ctaButton.url || ctaButton.page?.menuTitle} className="button-secondary button-small my-4" /></div>}
            </div>
            <div className="sm:max-md:w-1/3 aspect-3/2">
                <img src={image.url} alt={image.description || image.title } className="" />
            </div>
        </div>
    );
};

export default Card;

import { FC } from 'react';
import { IButton } from '@/components/Button';
import Card, { ICard } from '../Card';

export interface IFeaturedBlock {
    content: {
        heading: string;
        cardsCollection: {
            items: ICard[];
        }
    }
}

const FeaturedBlock: FC<IFeaturedBlock> = function ({ content}) {
    return (
        <section className="bg-whitepure" >
            <div className="container">
                <div className="flex max-md:flex-col gap-6">
                    {content.cardsCollection?.items?.map((card, i) => 
                        <div className="lg:w-1/3" key={`${i}`}>
                            <Card {...card} className={i % 2 ? 'md:flex-col-reverse sm:max-md:flex-row-reverse' : ''}/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FeaturedBlock;

import { FC } from 'react';
import { Entry } from 'contentful';
import { fieldsParser } from 'contentful-parsers';
import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

import FeaturedBlock from '@/components/FeaturedBlock';
import HeroBanner from '@/components/HeroBanner';
import ImageGallery from '@/components/ImageGallery';
import InfoBlock from '@/components/InfoBlock';
import TextWithImage from '@/components/TextWithImage';

const components: { [key: string]: any } = {
    FeaturedBlock,
    HeroBanner,
    ImageGallery,
    InfoBlock,
    TextWithImage,
};

interface IComponentBuilder {
    blocks: [];
}

const ComponentBuilder: FC<IComponentBuilder> = function ({ blocks }) {
    return (
        <>
            {blocks && blocks
                // Filter out any draft content, unpublished content, etc.
                // Contentful leaves the draft, etc. content in the response,
                // as placeholders (with empty `fields`) which would potentially
                // break the rendering here.
                .map((block, index) => {
                    const name: string = upperFirst(camelCase(block['__typename']));
                    const Component = components[name];
                    if (!Component) return '';

                    return (
                        <Component
                            key={`${Component}_${index}`}
                            content={block}
                        />
                    );
                })
            }
        </>
    );
};

export default ComponentBuilder;

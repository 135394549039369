import { FC } from 'react';
import { Document } from '@contentful/rich-text-types';
import RichTextRenderer from '../RichText';

export interface IImageGallery {
    content: {
        content: {
            json: Document;
        };
        imagesCollection: {
            items: {
                title: string;
                description: string;
                url: string;
            }[]
        }
        variant: string;
    }
}

const ImageGallery: FC<IImageGallery> = function ({ content}) {
    return (
        <>
            {content.imagesCollection.items.length < 2 ? <section className={`${!content.content ? 'max-h-[30rem] max-sm:aspect-video aspect-[3]' : ''} w-full bg-cover bg-center`} 
                style={{ backgroundImage: `url(${content.imagesCollection.items[0].url})`}} >          
                {content.content && <div className="container">
                    <div className={`flex ${content.variant === 'Text on the right' ? 'justify-end' : ''}`}>
                        <div className="sm:w-1/2 md:w-3/7">
                            <div className="bg-secondary p-8 max-sm:my-[4rem]">
                                <RichTextRenderer content={content.content} className="flex flex-col gap-4 text-center" />
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
            :
            <section className="bg-whitepure" >      
                <div className="container">
                    <div className={`flex ${content.variant === 'Text on the right' ? 'flex-row-reverse' : ''} max-sm:flex-col`}>
                        <div className="sm:w-1/2 md:w-3/5">
                            <div className="">
                                <RichTextRenderer content={content.content} className="flex flex-col gap-4" />
                                <img src={content.imagesCollection.items[0].url} alt={content.imagesCollection.items[0].description || content.imagesCollection.items[0].title}/>
                            </div>
                        </div>
                        <div className="sm:w-1/2 md:w-2/5">
                            <div className="flex sm:flex-col items-end">
                                {content.imagesCollection.items.slice(1).map((image, i) => <img key={`${image.url}-${i}`} src={image.url} alt={image.description || image.title} className="max-sm:w-1/2 max-sm:mb-4" />)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
        </>
    );
};

export default ImageGallery;
